import { Box } from '@mui/material';

import { Maybe } from '@/types';
import { ImageAsset as TImageAsset } from '@/types/generated';

import ImageAsset, { IImage } from '../ImageAsset/ImageAsset';

import { getSideImageBoxStyles } from './SingleMessage.styles';

export const SideImage = ({
	imageAsset,
	side = 'left',
	isSmallScreen,
	isHeaderAboveSideImages,
}: {
	imageAsset?: TImageAsset;
	side?: 'left' | 'right';
	isSmallScreen?: Maybe<boolean>;
	isHeaderAboveSideImages?: Maybe<boolean>;
}) => {
	const widthHeightSize = 'auto';

	const imageSx = {
		objectFit: 'none',
		height: widthHeightSize,
		width: widthHeightSize,
		minWidth: '0',
		minHeight: '0',
		maxWidth: '100%',
	};

	return (
		<Box sx={getSideImageBoxStyles(side, !!isHeaderAboveSideImages)}>
			<ImageAsset
				desktopImage={imageAsset?.desktopImage as IImage}
				mobileImage={imageAsset?.mobileImage as IImage}
				isBackgroundImage={false}
				imageSx={imageSx}
				dataTestId={`single_message_sideimage-${side}${isSmallScreen ? '-mobile' : ''}`}
			/>
		</Box>
	);
};
