import { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import { Maybe, Sizes } from '@/types';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';

import { iconSizeNumber } from './SingleMessage.helpers';
import { ISingleMessage } from './SingleMessage';

export interface ISingleMessageHeader {
	icon: ISingleMessage['icon'];
	iconSize: ISingleMessage['iconSize'];
	title: ISingleMessage['title'];
	isTitleCenterAligned?: boolean;
	eyebrow: ISingleMessage['eyebrow'];
	eyebrowSmallScreen?: Maybe<string>;
	isInlineLayout: ISingleMessage['isInlineLayout'];
	isContentCenterAligned?: boolean;
	isSmallScreen?: boolean;
	isDarkBackground?: boolean;
	children?: Maybe<ReactNode>;
}

export const TitleComponent = ({
	isSmallScreen,
	isDarkBackground,
	isTitleCenterAligned,
	title,
}: {
	title: ISingleMessage['title'];
	isTitleCenterAligned?: boolean;
	isSmallScreen?: boolean;
	isDarkBackground?: boolean;
}) => {
	return (
		<Typography
			variant={isSmallScreen ? 'header2' : 'heroMedium'}
			color={isDarkBackground ? 'text.light' : `primary.dark`}
			component="h1"
			textAlign={isSmallScreen || isTitleCenterAligned ? 'center' : 'left'}
			marginLeft={isTitleCenterAligned ? 'auto' : ''}
			marginRight={isTitleCenterAligned ? 'auto' : ''}
			marginBottom={'1rem'}
		>
			{title}
		</Typography>
	);
};

export default function SingleMessageHeader(props: Readonly<ISingleMessageHeader>) {
	const {
		icon,
		isContentCenterAligned,
		isSmallScreen,
		isDarkBackground,
		iconSize,
		eyebrow,
		eyebrowSmallScreen,
		title,
		isTitleCenterAligned,
	} = props;

	const eyebrowText = isSmallScreen && eyebrowSmallScreen ? eyebrowSmallScreen : eyebrow;

	return (
		<>
			{icon && (
				<Box
					className="single-message-header-icon"
					data-test-id="icon_single_message_container"
					sx={{
						textAlign: isSmallScreen || isContentCenterAligned ? 'center' : 'left',
					}}
				>
					<TAGSvgIcon
						htmlColor={isDarkBackground ? 'neutrals.white' : 'tertiary.main'}
						data-test-id="icon_single_message"
						icon={icon as IconName}
						size={iconSizeNumber(iconSize as Sizes)}
					/>
				</Box>
			)}

			{eyebrow && (
				<Box data-test-id="text_single_message_eyebrow_container">
					<Typography
						data-test-id="text_single_message_eyebrow"
						variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
						color={isDarkBackground ? 'text.light' : 'text.primary'}
						margin="0.75rem 0"
						letterSpacing="0.0625rem"
					>
						{eyebrowText}
					</Typography>
				</Box>
			)}
			{title ? (
				<TitleComponent
					{...{
						isSmallScreen,
						isDarkBackground,
						isTitleCenterAligned,
						title,
					}}
				/>
			) : null}
			{props.children}
		</>
	);
}
