import { Box } from '@mui/material';

import { ContentTypeRichTextContent, SmallScreenContent } from '@/types/generated';
import { metaData } from '@/constants';

import TermsAndConditions from '../TermsAndConditions';
import ContentTypeRichText from '../ContentTypeRichText';
import ContentfulButton from '../ContentfulButton';
import SubscriptionForm from '../SubscriptionForm';

import {
	getBodyContainerStyles,
	getContentAlignStyles,
	getSingleMessageButtonsWrapper,
	getSingleMessageRichTextContainerStyles,
} from './SingleMessage.styles';
import { ISingleMessage } from './SingleMessage';

export interface ISingleMessageContent {
	tc: ISingleMessage['tc'];
	sys: ISingleMessage['sys'];
	content: ISingleMessage['content'];
	eyebrow: ISingleMessage['eyebrow'];
	focalPointImage: ISingleMessage['focalPointImage'];
	buttonsCollection: ISingleMessage['buttonsCollection'];
	buttonText: ISingleMessage['buttonText'];
	buttonUrl: ISingleMessage['buttonUrl'];
	buttonType: ISingleMessage['buttonType'];
	buttonSize: ISingleMessage['buttonSize'];
	contentSmallScreen: ISingleMessage['contentSmallScreen'];
	contentFillVerticalSpace: ISingleMessage['contentFillVerticalSpace'];
	isInlineLayout: ISingleMessage['isInlineLayout'];
	isContentCenterAligned: boolean;
	isSmallScreen?: boolean;
	isDarkBackground?: boolean;
	isExtraSmallScreen?: boolean;
	showSubscriptionForm?: ISingleMessage['showSubscriptionForm'];
}

export default function SingleMessageContent({
	tc,
	sys,
	content,
	focalPointImage,
	buttonsCollection,
	buttonText,
	buttonUrl,
	buttonType,
	buttonSize,
	contentSmallScreen,
	contentFillVerticalSpace,
	isInlineLayout = false,
	isContentCenterAligned = false,
	isSmallScreen = false,
	isDarkBackground = false,
	isExtraSmallScreen = false,
	showSubscriptionForm = false,
}: Readonly<ISingleMessageContent>) {
	const singleMessageButtons = buttonsCollection?.items || [];
	const sysId = sys?.id;
	const { bodySmallScreen } = Object(contentSmallScreen) as SmallScreenContent;
	const bodyContent = isSmallScreen && bodySmallScreen ? bodySmallScreen : content;

	return (
		<>
			<Box
				data-test-id="single_message_rich_text_content_container"
				sx={{
					...getSingleMessageRichTextContainerStyles(
						isContentCenterAligned,
						isExtraSmallScreen,
						showSubscriptionForm
					),
					...(contentFillVerticalSpace && { flex: 1 }),
				}}
			>
				{content ? (
					<ContentTypeRichText
						contentMaxWidth="100%"
						disablePaddingOn={['Top', 'Bottom']}
						backgroundColor="transparent"
						dataTestId="section_single_message_rich_content"
						containerSx={getBodyContainerStyles(isSmallScreen)}
						content={bodyContent as ContentTypeRichTextContent}
						docProps={isDarkBackground || focalPointImage ? { color: 'text.light' } : {}}
						centerAlign={isContentCenterAligned}
						disableGutters={!isContentCenterAligned}
					/>
				) : null}
			</Box>
			{/* will be deprecated */}
			{!singleMessageButtons.length && buttonText && buttonUrl && buttonType && (
				<Box sx={getSingleMessageButtonsWrapper(isContentCenterAligned, isInlineLayout)}>
					<ContentfulButton
						_id={sysId}
						sys={sys}
						buttonLink={buttonUrl}
						label={buttonText}
						variant={buttonType}
						size={buttonSize}
						dataTestId="single_message_button"
						contentfulMetadata={metaData}
					/>
				</Box>
			)}

			{singleMessageButtons.length ? (
				<Box
					className="single-message-buttons"
					data-test-id="group_single_message_buttons_wrapper"
					sx={getSingleMessageButtonsWrapper(isContentCenterAligned, isInlineLayout)}
				>
					{singleMessageButtons.map((button) =>
						button ? (
							<ContentfulButton key={button.sys.id} {...button} dataTestId="single_message_button" />
						) : null
					)}
				</Box>
			) : null}

			{tc && (
				<Box
					data-test-id="group_single_message_terms_and_conditions"
					sx={getContentAlignStyles(
						isContentCenterAligned,
						isInlineLayout,
						isSmallScreen,
						showSubscriptionForm
					)}
				>
					<TermsAndConditions
						{...tc}
						textColor={isDarkBackground || focalPointImage ? 'text.light' : 'text.secondary'}
					/>
				</Box>
			)}
			{showSubscriptionForm && (
				<SubscriptionForm
					isDarkBackground={isDarkBackground}
					isSmallScreen={isSmallScreen}
					description="Subscribe to our newsletter for advice, promotions & more straight to your inbox!"
				/>
			)}
		</>
	);
}
