export const getTextFieldStyles = (isDarkBackground: boolean) => ({
	...(isDarkBackground && {
		'.MuiInputBase-root input': {
			color: 'text.light',
		},
		fieldset: {
			borderColor: 'neutrals.white',
		},
		'.MuiInputBase-root input:focus + fieldset': {
			borderColor: 'neutrals.white',
		},
		'.MuiInputBase-root:hover fieldset': {
			borderColor: 'neutrals.white',
		},
		'.MuiInputBase-root.Mui-error input': {
			color: 'error.main',
		},
		'.MuiInputBase-root.Mui-error input:focus + fieldset': {
			borderColor: 'error.main',
		},
		'.MuiInputBase-root.Mui-error:hover fieldset': {
			borderColor: 'error.main',
		},
		'.MuiFormHelperText-root.Mui-error': {
			color: 'error.main',
		},
	}),
});

export const getTextFieldInputStyles = (isDarkBackground: boolean) => ({
	...(isDarkBackground && {
		'&::placeholder': {
			color: 'white',
			opacity: 1,
		},
	}),
});

export const formWrapStyles = {
	display: 'flex',
	gap: '1rem',
	alignItems: { xs: 'left', md: 'start' },
	flexDirection: { xs: 'column', md: 'row' },
};

export const getFormFieldStyles = ({
	withBottomDescription,
	withLeftDescription,
}: {
	withBottomDescription: boolean;
	withLeftDescription: boolean;
}) => ({
	display: 'flex',
	gap: withBottomDescription ? '0.25rem' : '1.5rem',
	alignItems: 'flex-start',
	justifyContent: 'center',
	width: { xs: '100%', md: withLeftDescription ? '40%' : '100%' },
	flexDirection: withBottomDescription ? 'column' : 'row',
});
