import { Box, useTheme, useMediaQuery, SxProps } from '@mui/material';

import { ContentfulBackground, DisableTemplatePaddingOn, Maybe, TemplateWidth, TemplateHeight, Sizes } from '@/types';
import {
	SingleMessage as TSingleMessage,
	TermsAndConditions as TTermsAndConditions,
	FocalPointImage as TFocalPointImage,
	SingleMessageButtonsCollection,
	SmallScreenContent,
	ImageAsset as TImageAsset,
} from '@/types/generated';
import { textToJumpLink, useTemplateContainerFullWidthStyles } from '@/utils';

import { resolveContentfulBgColor, translateTagColorNameForContentful } from '../../utils/resolveContentfulBgColor';
import TemplateContainer from '../TemplateContainer/TemplateContainer';

import SingleMessageContent from './SingleMessageContent';
import {
	templateWrapperStyles,
	getContentWrapperStyles,
	getContentAlignStyles,
	sideImageMobileWrapperStyles,
	getGridHeaderStyles,
	getGridContentStyles,
	getSideImageGridStyle,
} from './SingleMessage.styles';
import { RenderImage } from './RenderImage';
import { SideImage } from './SideImage';
import SingleMessageHeader from './SingleMessageHeader';

// Note: move title to Header component TitleComponent not needed//
export interface ISingleMessage extends TSingleMessage {
	/**
	 * Required field for the SingleMessage id
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	name?: Maybe<string>;
	/**
	 * Collection of SIngleMessage buttons
	 *
	 * @type {Maybe<SingleMessageButtonsCollection>}
	 * @memberof ISingleMessage
	 */
	buttonsCollection?: Maybe<SingleMessageButtonsCollection>;
	/**
	 * Background color for the single message section coming from Contentful.
	 *
	 * @type {ContentfulBackground}
	 * @memberof ISingleMessage
	 */
	backgroundColor?: Maybe<string>;
	/**
	 * Specifies single message button size.
	 *
	 * @type {ContentfulButtonSize}
	 * @memberof ISingleMessage
	 */
	buttonSize?: Maybe<string>;
	/**
	 * Background image information for the hero section.
	 *
	 * @type {Maybe<TFocalPointImage>}
	 * @memberof ISingleMessage
	 */
	focalPointImage?: Maybe<TFocalPointImage>;
	/**
	 * Button text coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	buttonText?: Maybe<string>;
	/**
	 * Button variant coming from Contentful. Can be 'Primary Default' or 'Secondary Default'.
	 *
	 * @type {Primary Default | Secondary Default}
	 * @memberof ISingleMessage
	 * @default 'Primary Default'
	 */
	buttonType?: Maybe<string>;
	/**
	 * Button Url coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	buttonUrl?: Maybe<string>;
	/**
	 * Text content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	eyebrow?: Maybe<string>;
	/**
	 * Icon image coming from Contentful.
	 *
	 * @type {IconName}
	 * @memberof ISingleMessage
	 */
	icon?: Maybe<string>;
	/**
	 * Optional terms and conditions content coming from Contentful.
	 *
	 * @type {TTermsAndConditions}
	 * @memberof ISingleMessage
	 */
	/**
	 * Optional icon size for desktop
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	iconSize?: Maybe<string>;
	/**
	 * Optional terms and conditions displayed below content.
	 *
	 * @type {TTermsAndConditions}
	 * @memberof ISingleMessage
	 */
	tc?: Maybe<TTermsAndConditions>;
	/**
	 * Width of the SingleMessage template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof ISingleMessage
	 * @default 'Full'
	 */
	templateWidth?: Maybe<string>;
	/**
	 * Height of the SingleMessage template. Can be Regular or Full.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof ISingleMessage
	 * @default 'Regular'
	 */
	templateHeight?: Maybe<string>;
	/**
	 * Optional subtext content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	subText?: Maybe<string>;
	/**
	 * Optional description content coming from Contentful.
	 *
	 * @type {Maybe<Scalars['String']>}
	 * @memberof ISingleMessage
	 * @deprecated use content rich text instead
	 */
	description?: Maybe<string>;
	/**
	 * Title content coming from Contentful.
	 *
	 * @type {string}
	 * @memberof ISingleMessage
	 */
	title?: Maybe<string>;
	/**
	 * An option to either 'left' or 'center' align the title.
	 * @type {'left' | 'center'}
	 * @memberof ISingleMessage
	 * @default 'center'
	 */
	titleAlign?: Maybe<string>;
	/**
	 * A false value will display the title at the top of the component, separate from the inline layout.
	 * @type {boolean}
	 * @memberof ISingleMessage
	 * @default true
	 */
	displayTitleInline?: Maybe<boolean>;
	/**
	 * Optional prop for determining SingleMessage content placement.
	 *
	 * @type {'row' | 'row-reverse'}
	 * @memberof ISingleMessage
	 * @default undefined
	 * @deprecated not necessary to achieve layout - unlear intent
	 */
	direction?: Maybe<string>;
	/**
	 * Allows setting different content on small screens.
	 *
	 * @type {SmallScreenContent}
	 * @memberof ISingleMessage
	 * @default null
	 */
	contentSmallScreen?: Maybe<SmallScreenContent>;
	/**
	 * An option to disable left/right padding of content.
	 *
	 * @type {boolean}
	 * @memberof ISingleMessage
	 */
	disableGutters?: boolean;
	/**
	 * An option to disable padding on top and/or the bottom of the template.
	 *
	 * @type {Array<'Top' | 'Bottom'> | undefined}
	 * @memberof ISingleMessage
	 */
	disablePaddingOn?: DisableTemplatePaddingOn;
	/**
	 * An option to either 'left' or 'center' align the Rich Text content
	 * @type {'left' | 'center'}
	 * @memberof ISingleMessage
	 * @default 'center'
	 */
	contentAlign?: Maybe<string>;
	/**
	 * Brandfolder Image with focal point.
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof ISingleMessage
	 */
	imageAsset?: Maybe<TImageAsset>;
	/**
	 * Brandfolder Image to be displayed left of the body content.
	 * @type {Maybe<TImageAsset>}
	 * @memberof ISingleMessage
	 */
	imageLeftSide?: Maybe<TImageAsset>;
	/**
	 * Brandfolder Image to be displayed right of the body content.
	 * @type {Maybe<TImageAsset>}
	 * @memberof ISingleMessage
	 */
	imageRightSide?: Maybe<TImageAsset>;
	/**
	 * Specifies whether the component header section should rest above images and content.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	isHeaderAboveSideImages?: Maybe<boolean>;
	/**
	 * Specifies whether the content should grow vertically
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	contentFillVerticalSpace?: Maybe<boolean>;
	/**
	 * Specifies whether image is a banner above content
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	isBannerImage?: Maybe<boolean>;
	/**
	 * Enables an inline layout for icon and button
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	isInlineLayout?: Maybe<boolean>;
	/**
	 * Enables rounded corners for container
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	useRoundedCorners?: Maybe<boolean>;
	/**
	 * Display a subscription form
	 *
	 * @type {Maybe<boolean>}
	 * @memberof ISingleMessage
	 */
	showSubscriptionForm?: Maybe<boolean>;
}

function SingleMessage({
	name,
	contentSmallScreen,
	buttonsCollection,
	backgroundColor,
	disableGutters = false,
	disablePaddingOn,
	focalPointImage,
	templateHeight,
	templateWidth,
	contentAlign = 'center',
	buttonSize,
	buttonText,
	buttonType,
	buttonUrl,
	content,
	eyebrow,
	icon,
	iconSize = 'Large' as Sizes,
	sys,
	tc,
	title,
	titleAlign = 'center',
	imageAsset,
	imageLeftSide,
	imageRightSide,
	isHeaderAboveSideImages = false,
	isBannerImage,
	isInlineLayout = false,
	useRoundedCorners = false,
	showSubscriptionForm = false,
	contentFillVerticalSpace = false,
}: Readonly<ISingleMessage>) {
	const { focalPointImageSmallScreen, eyebrowSmallScreen } = Object(contentSmallScreen) as SmallScreenContent;

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isDarkBackground =
		backgroundColor?.toLowerCase().includes('dark') || backgroundColor?.toLowerCase() === 'saffron';
	const contentfulSafeBackgroundColor = translateTagColorNameForContentful(backgroundColor ?? '') || '';

	const bgColor = resolveContentfulBgColor(contentfulSafeBackgroundColor as ContentfulBackground, theme);

	const removePaddingFromContainer = isInlineLayout ? ['Top', 'Bottom'] : disablePaddingOn;
	const paddingStyles = useTemplateContainerFullWidthStyles(removePaddingFromContainer, isSmallScreen, true);
	const isContentCenterAligned = !contentAlign || contentAlign === 'center';
	const isTitleCenterAligned = !titleAlign || titleAlign === 'center';

	const idLabel = textToJumpLink(name);

	const hasSideImage = !isInlineLayout && !showSubscriptionForm && !!(imageLeftSide ?? imageRightSide);
	const hasSingleSideImage = hasSideImage && (!imageLeftSide || !imageRightSide);

	const showSideImagesInHeader = isSmallScreen && isContentCenterAligned && !hasSingleSideImage;
	const showSideImageOnSide = !isSmallScreen || (isSmallScreen && !isContentCenterAligned);
	const showLeftImage = showSideImageOnSide && imageLeftSide;
	const showRightImage = showSideImageOnSide && imageRightSide;
	const hasIcon = !!icon;

	const singleMessageContentProps = {
		tc,
		sys,
		content,
		eyebrow,
		focalPointImage,
		buttonsCollection,
		contentSmallScreen,
		contentFillVerticalSpace,
		isContentCenterAligned,
		isInlineLayout,
		isSmallScreen,
		isDarkBackground,
		buttonSize,
		buttonText,
		buttonType,
		buttonUrl,
		isExtraSmallScreen,
		showSubscriptionForm,
	};

	const singleMessageHeaderProps = {
		icon,
		isContentCenterAligned,
		isInlineLayout,
		isSmallScreen,
		isDarkBackground: isDarkBackground || !!focalPointImage,
		iconSize,
		eyebrow,
		eyebrowSmallScreen,
		title,
		isTitleCenterAligned,
	};

	const whichContentStyles: SxProps = hasSideImage
		? getGridContentStyles(isContentCenterAligned, !!imageLeftSide, !!imageRightSide)
		: {
				width: '100%',
				margin: '0 auto',
		  };

	const getContentBoxStyles = isInlineLayout
		? getContentAlignStyles(isContentCenterAligned, isInlineLayout, isSmallScreen, showSubscriptionForm)
		: whichContentStyles;

	return (
		<TemplateContainer
			id={`single-message-${idLabel}`}
			maxWidth={false}
			contentfulBackgroundColor={bgColor}
			templateWidth={(templateWidth as TemplateWidth) || 'Full'}
			templateHeight={(templateHeight as TemplateHeight) || 'Standard'}
			dataTestId={`section_single_message_${sys.id}`}
			containerSx={{ ...templateWrapperStyles(templateWidth === 'Full', !!useRoundedCorners), ...paddingStyles }}
		>
			<RenderImage
				imageAsset={imageAsset}
				focalPointImage={focalPointImage}
				focalPointImageSmallScreen={focalPointImageSmallScreen}
				isBannerImage={isBannerImage}
				isSmallScreen={isSmallScreen}
			/>
			<Box
				data-test-id="section_single_message_alpha"
				sx={
					hasSideImage
						? getSideImageGridStyle(isContentCenterAligned, !!imageLeftSide, !!imageRightSide)
						: getContentWrapperStyles({
								isSmallScreen,
								isContentCenterAligned,
								inlineLayout: !!isInlineLayout,
								removePadding: disableGutters,
								showSubscriptionForm: !!showSubscriptionForm,
								hasIcon,
						  })
				}
			>
				{showLeftImage && (
					<SideImage
						imageAsset={imageLeftSide}
						side={'left'}
						isHeaderAboveSideImages={isHeaderAboveSideImages}
						isSmallScreen={isSmallScreen}
					/>
				)}
				{(isInlineLayout && <SingleMessageHeader {...singleMessageHeaderProps} />) || (
					<Box
						data-test-id="single_message_header_wrapper"
						sx={getGridHeaderStyles(!!isHeaderAboveSideImages)}
					>
						<SingleMessageHeader {...singleMessageHeaderProps}>
							{showSideImagesInHeader && (
								<Box sx={{ ...sideImageMobileWrapperStyles }}>
									{imageLeftSide && (
										<SideImage
											imageAsset={imageLeftSide}
											side={'left'}
											isSmallScreen={isSmallScreen}
										/>
									)}
									{imageRightSide && (
										<SideImage
											imageAsset={imageRightSide}
											side={'right'}
											isSmallScreen={isSmallScreen}
										/>
									)}
								</Box>
							)}
						</SingleMessageHeader>
					</Box>
				)}
				<Box data-test-id="section_single_message_content" sx={getContentBoxStyles}>
					<SingleMessageContent {...singleMessageContentProps} />
				</Box>
				{/* </Box> */}
				{showRightImage && (
					<SideImage
						imageAsset={imageRightSide}
						side={'right'}
						isHeaderAboveSideImages={isHeaderAboveSideImages}
						isSmallScreen={isSmallScreen}
					/>
				)}
			</Box>
		</TemplateContainer>
	);
}

export default SingleMessage;
